import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Breadcrumb } from "react-bootstrap";
import { useFlexSearch } from "react-use-flexsearch";

import Layout from "../components/layout";
import Seo from "../components/seo";
import RecipeCard from "../components/recipeCard";

const uri = "/search/";

const SearchPage = ({ location, data }) => {
  const searchQuery = location.state?.searchQuery;
  const normalizedQuery = location.state?.normalizedQuery;

  const results = useFlexSearch(normalizedQuery, data.localSearchRecipes.index, data.localSearchRecipes.store);

  return (
    <Layout pageInfo={{ pageName: "search", searchQuery: searchQuery }}>
      <Seo title="Search" keywords={[`recipes`, `search`]} description="Recipe search" url={uri} />

      <Container className="text-center">
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Search</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <Row className="justify-content-center my-3">
          <h1>"{searchQuery}" search results</h1>
        </Row>
        <Row className="justify-content-center d-flex flex-wrap">
          {results.length === 0 ? (
            <>
              <b>Sorry, no recipes found. Please try another search.</b>
            </>
          ) : (
            results.map((result) => (
              <RecipeCard
                key={result.uri}
                title={result.title}
                uri={result.uri}
                image={result.image === null ? null : result.image.node.localFile.childImageSharp.gatsbyImageData}
                excerpt={result.excerpt}
                className="recipeSearchCard"
              />
            ))
          )}
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query SearchData {
    localSearchRecipes {
      index
      store
    }
  }
`;

export default SearchPage;
